const imageRowData = [
  {
    id: 1,
    image: "foodWoman.png",
    classname: "w-[5rem] h-[16rem]  hidden md:block",
  },
  {
    id: 2,
    image: "apron-man.png",
    classname: "w-[16rem] h-[16rem] hidden md:block",
  },
  {
    id: 3,
    image: "african-dl.png",
    image2: "african-dlBig.png",
    classname: "md:w-[16rem] md:h-[16rem]",
  },
  {
    id: 4,
    image: "african-dl2.png",
    image2: "african-dl2Big.png",
    classname: "md:w-[16rem] md:h-[16rem]",
  },
  {
    id: 5,
    image: "Small-business.png",
    classname: "w-[16rem] h-[16rem] hidden md:block",
  },
  {
    id: 6,
    image: "oldWoman.png",
    classname: "w-[8rem] h-[16rem] hidden md:block",
  },
];

function ImageContainer(prop) {
  const { image, id, classname, image2 } = prop;

  const largeImageId = [3, 4];

  return (
    <div className={classname}>
      <img
        src={`images/${largeImageId.includes(id) ? image2 : image}`}
        alt={`header${id}`}
        className={"w-full h-full object-cover"}
      />
    </div>
  );
}

export function ImageRow() {
  return (
    <div className="thin-scrollbar h-[267px] grid grid-flow-col justify-between  mr-auto ml-auto w-fit md:w-full gap-x-3 overflow-hidden mb-20 lg:mb-28 ">
      {imageRowData.map((item) => {
        return <ImageContainer {...item} key={item.id} />;
      })}
    </div> 
  );
}