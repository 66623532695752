import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { useState } from "react";

export function Questions({id, question, answer}) {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div
      key={id}
      className=" border-b border-gray-200 min-h-[4rem] flex flex-col items-center pb-2"
    >
      <div
        className={`mt-auto flex w-full h-fit justify-between  items-center mb-2 ${
          showInfo ? "purple" : " "
        }`}
      >
        <h1 className=" text-lg font-medium">{question}</h1>
        <button onClick={() => setShowInfo(!showInfo)}>
          {showInfo ? (
            <FaChevronUp />
          ) : (
            <FaChevronDown className=" text-[#9C9C9C]" />
          )}
        </button>
      </div>
      {showInfo && <p className=" text-[#434046] text-sm">{answer}</p>}
    </div>
  );
}
