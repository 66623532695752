export function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="px-4 lg:px-[4vw] py-6 bg-[#DEE9FF] grid place-items-center gap-y-6 ">
      <div className="w-[60%] max-w-[160px]">
        <img
          src="images/creditry-logo-main.svg"
          alt="footer logo"
          className=" w-full"
        />
      </div>
      <p className=" text-center text-[1rem] font-medium text-[#434046]">
        A financial service firm helping businesses grow.
      </p>
      {/* social icons */}
      <div className=" flex gap-x-6 ">
        <div>
          <a href="#">
            <img className="cursor-pointer" src="images/socials/instagram.svg" alt="instagram" />
          </a>
        </div>
        <div>
          <a href="https://www.linkedin.com/company/creditry">
            <img className="cursor-pointer" src="images/socials/linkedin-color.svg" alt="linkedin" />
          </a>
        </div>
        <div>
          <a href="https://twitter.com/creditry">
            <img className="cursor-pointer" src="images/socials/twitter-color.svg" alt="twitter" />
          </a>
        </div>
        <div>
          <a href="#">
            <img className="cursor-pointer" src="images/socials/facebook-color.svg" alt="facebook" />
          </a>
        </div>
        <div>
          <a href="#">
            <img className="cursor-pointer" src="images/socials/medium.svg" alt="medium" />
          </a>
        </div>
      </div>
      <div> &copy; {currentYear} </div>
    </footer>
  );
}
