import { NavBar } from "./components/navbar";
import { Header } from "./components/header";
import { MainContent } from "./components/mainContent";
import { Footer } from "./components/footer";
import { useEffect } from "react";
import { ImageRow } from "./components/imageRow";

function App() {

  useEffect(() => {
    document.title = "Creditry - A financial service firm helping businesses grow"
  });
  
  return (
    <>
    <NavBar/>
    <Header/>
    <ImageRow />
    <MainContent/>
    <Footer/>
    </>
  );
}

export default App;
