export function Header() {
  return (
    <header className=" mt-12 mb-20 lg:mt-20">
      <div className="mb-5 w-full">
        <h1 className=" text-center text-4xl lg:text-[40px] font-semibold mb-12 px-4 lg:px-0 md:w-[80%] lg:w[640px] mx-auto p-0 leading-[48px] md:leading-[60px]">
          Expand Your Business With Our SME Loan Financing With no Collateral.
        </h1>
      </div>
      <div>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScBVpv6-xabQP3rSFrbX_sKdEZJgE7VC2EyIuDD4s4cPOgwAQ/viewform?usp=sf_link" target="_blank" className=" bg-[#8524D6] font-medium text-white w-[12rem] text-center mx-auto text-base lg:text-sm lg:font-semibold lg:leading-[18.78px] py-3 px-6 rounded-md block">
              Join waitlist
          </a>
      </div>
    </header>
  );
}