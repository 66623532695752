export const NavBar = () => {
  return (
    <nav className="flex justify-between lg:justify-evenly items-center align-middle lg:mt-4 w-full relative overflow-hidden px-4 lg:px-0">
      {/* desktop */}
      {/*  site logo */}

      <div className="">
         <a href="/">
           <img src="images/creditry-logo-main.svg" alt="site-logo" />
         </a>
      </div>

      {/* desktop nav */}
      
      <div className="hidden gap-10 justify-start align-middle sm:hidden md:hidden lg:flex">
        <a href="/" className="text-sm text-[#333333] font-medium hover:text-[#477BE1]">
          Home
        </a>
        <a href="#about-us" className="text-sm text-[#333333] font-medium hover:text-[#477BE1]">
          About Us
        </a>
        <a href="#features" className="text-sm text-[#333333] font-medium hover:text-[#477BE1]">
          Features
        </a>
        <a href="#partners" className="text-sm text-[#333333] font-medium hover:text-[#477BE1]">
          Partnership
        </a>
        <a href="#" className="text-sm text-[#333333] font-medium hover:text-[#477BE1]">
          Blog
        </a>
        <a href="#faqs" className="text-sm text-[#333333] font-medium hover:text-[#477BE1]">
          FAQs
        </a>
      </div>

      {/* cta */}

      <div className="hidden lg:flex sm:hidden md:hidden">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScBVpv6-xabQP3rSFrbX_sKdEZJgE7VC2EyIuDD4s4cPOgwAQ/viewform?usp=sf_link" target="_blank"
        className="text-white text-base bg-[#8524D6] py-3 px-6 rounded-md block font-medium text-center lg:text-sm lg:font-semibold lg:leading-[18.78px]" >
          Join waitlist
        </a>
      </div>

      {/* desktop ends */}

      {/* hamburger menu */}
        <button className=" h-12 w-12 lg:hidden" onClick={() => {
          const mobileNav = document.querySelector(".mobile-nav");
          mobileNav.classList.add("showMenu");

        }}>
         <img src="images/icons/hamburger-menu.svg" alt="menu-button" />
       </button>

      {/* mobile nav */}

      <div className="mobile-nav hidden lg:hidden flex-col justify-start mobile-nav-container py-6 px-10 fixed left-0 top-0 right-0 z-[10] h-auto bg-white">
        <div>
        <button
            className=""
            onClick={() => {
              const mobileNav = document.querySelector(".mobile-nav");
              mobileNav .classList.remove("showMenu");
            }}
          >
            <img className="w-6 h-6 absolute right-8 top-[50px]" src="images/icons/cancel.svg" alt="cancel" />
          </button> 

        </div>
        <div className="flex flex-col justify-start items-start">
          <a href="/" className="text-2xl mt-[0.5rem] mobile-nav-link text-[#333333] font-medium hover:text-[#477BE1]" onClick={() => {
            const mobileNav = document.querySelector(".mobile-nav");
            mobileNav.classList.remove("showMenu");
          }}>
            Home
          </a>
          <a href="#about-us" className="text-2xl mt-[0.5rem] mobile-nav-link text-[#333333] font-medium hover:text-[#477BE1]" onClick={() => {
            const mobileNav = document.querySelector(".mobile-nav");
            mobileNav.classList.remove("showMenu");
          }}>
            About Us
          </a>
          <a href="#features" className="text-2xl mt-[0.5rem] mobile-nav-link text-[#333333] font-medium hover:text-[#477BE1]" onClick={() => {
            const mobileNav = document.querySelector(".mobile-nav");
            mobileNav.classList.remove("showMenu");
          }}>
            Features
          </a>
          <a href="#partners" className="text-2xl mt-[0.5rem] mobile-nav-link text-[#333333] font-medium hover:text-[#477BE1]" onClick={() => {
            const mobileNav = document.querySelector(".mobile-nav");
            mobileNav.classList.remove("showMenu");
          }}>
            Partnership
          </a>
          <a href="#" className="text-2xl mt-[0.5rem] mobile-nav-link text-[#333333] font-medium hover:text-[#477BE1]" onClick={() => {
            const mobileNav = document.querySelector(".mobile-nav");
            mobileNav.classList.remove("showMenu");
          }}>
            Blog
          </a>
          <a href="#faqs" className="text-2xl mt-[0.5rem] mobile-nav-link mb-[0.5rem] text-[#333333] font-medium hover:text-[#477BE1]" onClick={() => {
            const mobileNav = document.querySelector(".mobile-nav");
            mobileNav.classList.remove("showMenu");
          }}>
            FAQs
          </a>
        </div>
        <div>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScBVpv6-xabQP3rSFrbX_sKdEZJgE7VC2EyIuDD4s4cPOgwAQ/viewform?usp=sf_link" target="_blank"
            className="text-white mt-4 text-2xl bg-[#8524D6] py-3 px-6 rounded-md block w-[180px] font-medium text-center" onClick={() => {
              const mobileNav = document.querySelector(".mobile-nav");
              mobileNav.classList.remove("showMenu");
            }}>
            Join waitlist
          </a>
      </div>
    </div>
    </nav>
  );
};
