
import { Questions } from "./questions";

const benefitCardData = [
  {
    id: 1,
    img: "lowerrates.svg",
    benefit: "LOWER RATES",
    info: "Creditry offers loan to customers at relatively lower rates compared to our competitors",
  },
  {
    id: 2,
    img: "quickeasy.svg",
    benefit: "QUICK & EASY",
    info: "It is easy to access our loans either via our partners or from our website or application",
  },
  {
    id: 3,
    img: "nopaymentpenalty.svg",
    benefit: "NO PAYMENT PENALTY",
    info: "It is easy to access our loans either via our partners or from our website or application",
  },
  {
    id: 4,
    img: "secure.svg",
    benefit: "SECURE PROCESS",
    info: "Our platform is 100% secured and we guarantee safety of your personal and business data.",
  },
  {
    id: 5,
    img: "timer.svg",
    benefit: "5 MINS APPLICATION",
    info: "It takes just 5 mins to make an application on the Creditry platform.",
  },
  {
    id: 6,
    img: "flexiblepayment.svg",
    benefit: "FLEXIBLE PAYMENT",
    info: "There is flexibility in making your loan repayment from 3-6 month.",
  },
];

const faqData = [
  {
    id: 1,
    question: "  Who is this loan for?",
    answer:
      " Creditry is a financial service firm helping businesses grow. we welcome every businesses, no matter your previous credit or loan history. To get a loan offer, Get started with Creditry.",
  },
  {
    id: 2,
    question: "  Who is this loan for?",
    answer:
      " Creditry is a financial service firm helping businesses grow. we welcome every businesses, no matter your previous credit or loan history. To get a loan offer, Get started with Creditry.",
  },
  {
    id: 3,
    question: "  Who is this loan for?",
    answer:
      " Creditry is a financial service firm helping businesses grow. we welcome every businesses, no matter your previous credit or loan history. To get a loan offer, Get started with Creditry.",
  },
  {
    id: 4,
    question: "  Who is this loan for?",
    answer:
      " Creditry is a financial service firm helping businesses grow. we welcome every businesses, no matter your previous credit or loan history. To get a loan offer, Get started with Creditry.",
  },
  {
    id: 5,
    question: "  Who is this loan for?",
    answer:
      " Creditry is a financial service firm helping businesses grow. we welcome every businesses, no matter your previous credit or loan history. To get a loan offer, Get started with Creditry.",
  },
  {
    id: 6,
    question: "  Who is this loan for?",
    answer:
      " Creditry is a financial service firm helping businesses grow. we welcome every businesses, no matter your previous credit or loan history. To get a loan offer, Get started with Creditry.",
  },
];


export function MainContent() {

  return (
    <main className="mb-10">
      {/* partners */}
      <section className="mb-20">
        <div className="mx-auto md:w-[471px]">
          <h3 className=" text-blue-500 text-center font-bold text-[14px] mb-5">PARTNERS</h3>
          <p className="font-medium text-[1rem] text-center px-3 leading-6 lg:px-0">We are in partnership with several leading ecommerce store in Nigeria providing loans to over 8000 SMEs.</p>
        </div>
      </section>

      {/* about us */}
      <section id="about-us" className="px-8 mb-20 md:px-[4vw] lg:px-[12vw] md:flex md:justify-between md:mb-22 relative about-us">
        <div className=" w-fit relative ml-auto mr-auto mb-12 -translate-x-3 md:translate-x-0 md:ml-0 md:w-[50%]">
          <img src="images/fashion-designer.jpg" alt="about-us" className="about-us-img w-[464.44px] h-[322.76px] lg:w-[692px] lg:h-[467px] object-cover" />
          {/* <div className=" bg-blue-100 absolute w-[330.44px] h-[322.76px] lg:h-[478px] lg:w-[500px] lg:left-14 lg:top-8 left-6 top-6 -z-10"></div> */}
          <div className=" bg-blue-100 absolute h-full w-full lg:w-[500px] lg:h-[478px] left-6 top-8 lg:left-14 -z-10"></div>

        </div>
        {/* about us content */}
        <div className=" flex flex-col gap-y-4 md:w-[40%] md:h-fit md:mt-auto md:mb-auto  ">
          <h3 className=" text-blue-500 font-bold text-[14px]">ABOUT US</h3>
          <h2 className=" font-[700] lg:font-[600] leading-[30px] text-[20px] lg:text-[2rem]">
            What Creditry is and Why we exist?
          </h2>
          <p className=" text-base font-medium text-[#333333]">
            Creditry is a financial service firm helping businesses grow. we
            welcome every businesses, no matter your previous credit or loan
            history. To get a loan offer, Get started with Creditry.
          </p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScBVpv6-xabQP3rSFrbX_sKdEZJgE7VC2EyIuDD4s4cPOgwAQ/viewform?usp=sf_link" target="_blank" className=" bg-[#8524D6] font-medium text-white h-[3rem] w-[10rem] text-center text-[1rem] p-3 px-6 rounded-md block">
              Join waitlist
          </a>
        </div>
      </section>

      {/* benefits  */}
      <section id="features" className="relative  bg-[#F8F0FF] lg:px-[3vw]  pt-10 pb-8 lg:pb-20 mb-20">

        {/* benefits content */}
        <div className=" mt-5 flex flex-col gap-y-6 text-center md:text-left justify-center items-center mb-10 lg:mb-16 px-6 lg:px-[8vw] md:flex-row md:justify-between md:mt-[1rem] ">
          <div>
            <h3 className="text-blue-500 font-bold text-[14px]">
              OUR BENEFITS AND FEATURES
            </h3>
            <h2 className="font-[600] text-[20px] lg:text-[2rem]">Why you should Use Creditry?</h2>
          </div>
        </div>

        {/* benefits card */}
        <article className="px-4 lg:px-[8vw] md:grid lg:gap-y-12 gap-6 md:grid-cols-3 lg:gap-12">
          {benefitCardData.map((item) => {
            const { id, benefit, info, img } = item;
            return (
              <div
                key={id}
                className=" text-center w-auto h-[200px] lg:w-[338px] lg:h-[288px] lg:ml-auto lg:mr-auto bg-white flex flex-col justify-center justify-items-center items-center py-[2rem] gap-y-6  mb-8"
              >
                <div className="">
                  <img src={`images/icons/${img}`} alt={benefit} />
                </div>
                <h3 className=" font-semibold tracking-wide text-[14px] lg:text-[1.125rem] ">{benefit}</h3>
                <p className=" font-medium text-[12px] lg:text-[1rem] text-[#333333] px-[5px] lg:px-[1rem]">{info}</p>
              </div>
            );
          })}
        </article>
      </section>

      {/* how to apply  */}
      <section className=" px-8 md:px-[4vw] lg:px-[11vw] mb-[4rem] lg:mb-[12rem] md:mb-16 md:flex md:justify-between">
        <div className=" w-fit  relative ml-auto mr-auto mb-12 -translate-x-3 md:-translate-x-6 md:mr-0 md:w-[50%] md:order-1">
          <img
            src="images/cashier.jpg"
            alt="about-us"
            className="w-full lg:w-[550px] object-contain lg:h-[500px] "
          />
          <div className=" bg-blue-100 absolute h-full w-full lg:w-[550px] lg:h-[500px] left-6 top-8 -z-10"></div>
        </div>
        {/* how to apply content */}
        <div className=" flex flex-col gap-y-4 md:w-[40%] md:h-fit md:mt-auto md:mb-auto ">
          <h3 className=" text-blue-500 font-bold text-[14px]">HOW TO APPLY</h3>
          <h2 className="font-[700] lg:font-[600] leading-[30px] text-[20px] lg:text-[2rem]">
            Apply for our SME loan in these simple steps
          </h2>
          <div className=" grid gap-y-9">
            <div className=" flex gap-x-5  items-center">
              <p className=" min-h-[2.5rem] min-w-[2.5rem] bg-[#DEEAFF] text-center rounded-full grid place-items-center font-semibold text-[1rem]">
                1
              </p>
              <p className="text-[1rem] font-normal">Create an account</p>
            </div>
            <div className=" flex gap-x-5 items-center ">
              <p className=" min-h-[40px] min-w-[40px] bg-[#DEEAFF] text-center rounded-full grid place-items-center font-semibold text-[1rem]">
                2
              </p>
              <p className="text-[1rem] font-normal">Provide us with your documents</p>
            </div>
            <div className=" flex gap-x-5 items-center">
              <p className=" min-h-[2.5rem] min-w-[2.5rem] bg-[#DEEAFF] text-center rounded-full grid place-items-center font-semibold text-[1rem]">
                3
              </p>
              <p className="text-[1rem] font-normal">Apply for an amount and get disbursed</p>
            </div>
          </div>
        </div>
      </section>

      {/* partners  */}
      <section id="partners" className=" mb-16">
        <div className="partner-bg w-full h-[450px] md:h-[300.62px]; lg:h-[450px] relative overflow-hidden flex flex-row"> {/**relative h-[80vh] md:h-[65vh] md:flex md:justify-between */}
          <div className=" -z-[10] absolute">
            <img src="images/partner.png" className="h-[500px] w-[700px] object-cover" alt="" />
          </div>

          <div className="absolute md:left-[50%] lg:left-auto lg:right-60 bottom-6 md:bottom-14 lg:bottom-6 lg:top-36 lg:w-[459px] flex flex-col px-8 justify-start items-start gap-3 lg:gap-y-7">
            <h1 className="font-[600] leading-[30px] text-[20px] lg:text-[2rem]">Become a Partner</h1>
              <p className=" text-[1rem] font-medium text-[#333333]">
                We are in partnership with several leading ecommerce store in
                Nigeria providing loans to over 8000 SMEs.
              </p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSccxffJaEoJKbNUV-Eo_p0a7S6D5TBygX4_Ssy8Qv1l4OB71g/viewform?usp=sf_link" target="_blank" className=" bg-[#8524D6] font-semibold text-[14px] text-white  w-[10rem] text-center  p-3 px-6 rounded-md block">
              Partner with Us
            </a>
          </div>
          {/* <div className=""> *absolute -z-10 w-full h-full md:w-[50%] -top-36 md:top-0 */}
            {/* <img
              src="images/partner.png"
              alt="partner"
              className=" w-full h-full object-cover md:hidden  "
            /> */}
            {/* <img
              src="images/partner.png"
              alt="partner"
              className=" w-full h-full partner-lg-image hidden md:block "
            /> */}
        </div>
      </section>

      {/* frequently asked questions  */}
      <section id="faqs" className=" px-4 md:px-[4vw] lg:px-[12vw] md:flex md:justify-between">
        <div className=" md:w-[40%]">
          <h3 className=" text-blue-500 font-bold text-[14px]">
            FREQUENTLY ASKED QUESTIONS
          </h3>
          <h1 className="font-[700] lg:font-[600] leading-[30px] text-[20px] lg:text-[2rem]">
            Answers to some questions You might have!!
          </h1>
          <div className=" hidden md:block">
            <img src="images/question-mark.png" alt="question-mark" />
          </div>
        </div>

        {/* questions */}
        <div className="md:w-[50%]">
          {faqData.map((item) => {
            return (
              <Questions key={item.id} {...item} />
            );
          })}
        </div>
      </section>
    </main>
  );
}
